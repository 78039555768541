import React,{useState} from 'react'

import './Header.scss'

const Header = (props) => {

  const [data, setData] = useState(props.data);

  return (
    <div id="header">
        <div className="gradient" style={{
          backgroundImage: 'url("' + process.env.REACT_APP_BACKEND_URL + data["Background_Image"].data.attributes.url + '")'
        }}>
          <div className="container">
              <div className="header__content" data-aos="slide-up">
                  <h5>{data["Primary_Heading"]}</h5>
                  <h1 className='hide-onmobile'>{data["Heading_1"]}</h1>
                  <h1 className='hide-onmobile'>{data["Heading_2"]}</h1>
                  <h1 className='show-onmobile w-90'>{data["Heading_1"] + " " + data["Heading_2"]}</h1>
                  <p>{data["Description"]}</p>
                  <a href={data["CTA_Button_Link"]}><button className='btn'>{data["CTA_Button_Name"]}</button></a>
              </div>
          </div>
      </div>
    </div>
  )
}

export default Header