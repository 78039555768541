import React, {useState} from 'react'

import './ImgMiddle.scss'
import IMG from '../../assets/service_apartment.svg'

const ImgMiddle = (props) => {

  const [data, setData] = useState(props.data);

  return (
    <div className="img__middle">
        <div className='container'>
            <p className="heading-25">{data["Title"]}</p>
        <div className='imgmiddle__container'>
            <p className="p-18 color-gray-80">{data["Text_Left"]}</p>
            <img src={process.env.REACT_APP_BACKEND_URL + data["Image"].data.attributes.url} alt="Image" />
            <p className="p-18 color-gray-80">{data["Text_Right"]}</p>
        </div>
        </div>
    </div>
  )
}

export default ImgMiddle