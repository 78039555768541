import React, {useState} from 'react'

import './BusinessPark.scss'
import ABOUTUS_IMG from '../../assets/aboutus.svg'
import BUSINESSPARK_IMG from '../../assets/businesspark.svg'

const BusinessPark = (props) => {

    const [data, setData] = useState(props.data);

  return (
    <div id="business-park">
        <div className="container">
            <div className="businesspark__container">
                <img src={process.env.REACT_APP_BACKEND_URL + data["Image"].data.attributes.url} className='bpark__img' alt="Image" />
                <div className='businesspark__details'>
                    <p className="heading-25 text-align-left mb-1">{data["Title"]}</p>
                    <p className="p-18 text-align-left color-gray-80 mb-1">{data["Description_1"]}</p>
                    <p className="p-18 text-align-left color-gray-80 mb-1">{data["Description_2"]}</p>
                    <div className="businesspark__subcontainer">
                        <img src={process.env.REACT_APP_BACKEND_URL + data["Image_Right"].data.attributes.url} alt="Business Park Image" />
                        <p className="p-18 text-align-left color-gray-80">{data["Description_Right_Image"]}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BusinessPark