import React, {useState} from 'react'

import './ComingSoon.scss'
import COMINGSOON_IMG from '../../assets/coming_soon.svg'
import LOGO_IMG from '../../assets/logo_lahore.svg'
import COMINGSOON_BACKGROUND from '../../assets/coming_soon_background.svg'

const ComingSoon = (props) => {

  const [data, setData] = useState(props.data);

  return (
    <div id='coming-soon'>
        <div className="container comingsoon__container">
            <img src={process.env.REACT_APP_BACKEND_URL + data["Primary_Image"].data.attributes.url} alt="Logo Image" className='logo__img'/>
            <img src={process.env.REACT_APP_BACKEND_URL + data["Secondary_Image"].data.attributes.url} alt="Coming Soon Image" className='comingsoon__img'/>
            <p className='p-20'>{data["Description"]}</p>
            {/* <button className='p-18 btn'>Learn More</button> */}
        </div>
    </div>
  )
}

export default ComingSoon       