export const useInViewOptions = {
    threshold: 0.1,
}

export const navLinks = [
    {
        key: 'aboutus',
        path: '/#aboutus',        
        display: 'About Us'
    },
    {
        key: 'siliconvillage',
        path: '/#siliconvillage',        
        display: 'Silicon Village'
    },
    {
        key: 'stza',
        path: '/#stza',        
        display: 'STZA'
    },
    {
        key: 'capitalsmartcity',
        path: '/#capitalsmartcity',        
        display: 'Capital Smart City'
    },
    {
        key: 'university',
        path: '/#university',        
        display: 'University Campus'
    },
    {
        key: 'facilities',
        path: '/#facilities',        
        display: 'Facilities'
    },
    {
        key: 'molecule',
        path: '/#molecule',        
        display: 'Molecule'
    }
]