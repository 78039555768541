import React, {useEffect, useState} from 'react'

import './Stza.scss'
import HAND_IMG from '../../assets/hand.svg'
import HAND_SUPPORT_IMG from '../../assets/hand_support.svg'
import MONEY_IMG from '../../assets/money.svg'
import PEOPLE_NETWORK_IMG from '../../assets/people_network.svg'
import PEOPLE_POOL_IMG from '../../assets/people_pool.svg'
import { useInView } from 'react-intersection-observer';
import { useInViewOptions } from '../../utils/constants';
import { removeSelectedNavItem, selectActiveItemOnNav } from '../../utils/functions';

const Stza = (props) => {

    const [data, setData] = useState(props.data);
    const { ref, inView, entry } = useInView(useInViewOptions);

  useEffect(() => { 
    if (inView)  {
        selectActiveItemOnNav('stza');
    } else removeSelectedNavItem('stza');
  }, [inView]);
    
  return (
    <div id='stza' ref={ref}>
        <div className="container">
            <p className='heading-35'>{data["Title"]}</p>
            <p className='p-22 stza__description'>{data["Description"]}</p>

            <div className="stza__points">
                {
                    data["Options"].map((option) => {
                        return(<div className="stza__singlepoint">
                            <img src={process.env.REACT_APP_BACKEND_URL + option["Icon"].data.attributes.url} alt="STZA POINT IMAGE" />
                            <p className='p-20'>{option["Text"]}</p>
                        </div>)
                    })
                }
            </div>
        </div>
    </div>
  )
}

export default Stza