import React, {useEffect, useState} from 'react'

import './Molecule.scss'
import MOLECULE_LOGO from '../../assets/molecule_logo.svg'
import MOLECULE_PARENT_IMG from '../../assets/molecule_parent.svg'
import MOLECULE_CHILD_IMG from '../../assets/molecule_child.svg'
import { useInView } from 'react-intersection-observer';
import { useInViewOptions } from '../../utils/constants';
import { removeSelectedNavItem, selectActiveItemOnNav } from '../../utils/functions';


const Molecule = (props) => {

    const { ref, inView, entry } = useInView(useInViewOptions);
    const [data, setData] = useState(props.data);

  useEffect(() => { 
    if (inView)  {
        selectActiveItemOnNav('molecule');
    } else removeSelectedNavItem('molecule');
  }, [inView]);

    return (
    <div id='molecule' ref={ref}>
        <div className="container molecule__container">
            <img src={process.env.REACT_APP_BACKEND_URL + data["Logo_Image"].data.attributes.url} className='molecule__logo' alt="Molecule Logo" />
            <p className="heading-28 pb-1">{data["Title"]}</p>
            <p className='p-18 molecule__description mb-3'>{data["Description"]}</p>
            <img src={process.env.REACT_APP_BACKEND_URL + data["Main_Image"].data.attributes.url} className='w-100 mb-5 molecule__weblogo' alt="Molecule Image" />

            <div className='molecule__subcontainer w-100'>
                <div>
                    <p className="heading-28 text-align-left mb-2">{data["Sub_Title"]}</p>
                    <ul className='text-align-left p-21'>
                        
                        {
                            data["Sub_Description_List"].map((listItem) => {
                                return <li>{listItem}</li>
                            })
                        }
                    </ul>
                </div>

                <img src={process.env.REACT_APP_BACKEND_URL + data["Sub_Image"].data.attributes.url} className='childmolecule__logo' alt="Molecule Image" />

            </div>

            <a href="https://molecule.com.pk" target='_blank' style={{marginTop: '3rem'}}>
                <button className='btn find-more-btn'>Find out more</button>
            </a>
        </div>
    </div>
  )
}

export default Molecule