import React, {useEffect, useState} from 'react'
import axios from 'axios'

import {FaFacebookSquare} from 'react-icons/fa'
import {RiInstagramFill} from 'react-icons/ri'
import {AiFillTwitterSquare} from 'react-icons/ai'
import {AiFillLinkedin} from 'react-icons/ai'
import {AiFillYoutube} from 'react-icons/ai'
import {IoMdCall} from 'react-icons/io'
import {MdEmail} from 'react-icons/md'
import {HiLocationMarker} from 'react-icons/hi'
import {IoLogoWhatsapp} from 'react-icons/io'

import ISB_IMG from '../../assets/logo.svg'
import LHR_IMG from '../../assets/logo_lahore.svg'

import './Footer.scss'

const Footer = () => {

    const [data, setData] = useState(null);

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + '/api/footer?populate=deep')
          .then(function (response) {
            // handle success
            setData(response.data.data.attributes.Footer);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          })
          .finally(function () {
            // always executed
          });
      }, []);

  return (
    data!== null && (
        <div id='footer'>
            <div className="container footer__container">
                
                <div>
                    <p className="heading-25 mb-1" dangerouslySetInnerHTML={{__html:data["Title"]}}></p>
                    <p className="p-13 mb-1 footer__description" style={{lineHeight: '22px'}}>{data["Description"]}</p>
                    <div className="social__icons">
                        {
                            data["Social_Media_Icons"].map((icon) => {
                                return(
                                    <a target="_blank" href={icon.Link}><img src={process.env.REACT_APP_BACKEND_URL + icon.Icon.data.attributes.url} alt="" /></a>
                                )
                            })
                        }
                        {/* <a target="_blank" href="https://www.facebook.com/siliconvillagepakistan/"><FaFacebookSquare /></a>
                        <a target="_blank" href="https://www.instagram.com/siliconvillagepakistan/"><RiInstagramFill /></a>
                        <a target="_blank" href="https://twitter.com/siliconpak"><AiFillTwitterSquare /></a>
                        <a target="_blank" href="https://www.linkedin.com/company/siliconvillagepakistan/"><AiFillLinkedin /></a>
                        <a target="_blank" href="https://www.youtube.com/@siliconvillagepakistan"><AiFillYoutube /></a> */}
                    </div>
                </div>

                {/* <div className='footer__col'>
                    <img src={ISB_IMG} className="brand__img" alt="Islamabad Silicon Village Image" />
                    <div className="social__icons ml-auto mr-auto mt-1">
                        <a href="https://www.facebook.com/siliconvillageislamabad/"><FaFacebookSquare /></a>
                        <a href="https://www.instagram.com/siliconvillageislamabad/"><RiInstagramFill /></a>
                        <a href="https://twitter.com/siliconisb"><AiFillTwitterSquare /></a>
                    </div>
                </div>

                <div className='footer__col'>
                    <img src={LHR_IMG} className="brand__img" alt="Lahore Silicon Village Image" />
                    <div className="social__icons ml-auto mr-auto mt-1">
                        <a href="https://www.facebook.com/siliconvillagelahore/"><FaFacebookSquare /></a>
                        <a href="https://www.instagram.com/siliconvillagelahore/"><RiInstagramFill /></a>
                        <a href="https://twitter.com/siliconlhr"><AiFillTwitterSquare /></a>
                    </div>
                </div> */}

                {/* 
                <div className='footer__col'>
                    <p className="heading-20">Support</p>
                    <a className="p-13">WhatsApp</a>
                    <a className="p-13">Onlie Chat</a>
                    <a className="p-13">Call Center</a>
                    <a className="p-13">Contact Us</a>
                    <a className="p-13">Help</a>
                </div> */}

                <div className='footer__col'>
                    <p className="heading-20">{data["Contact_Info_Heading"]}</p>
                    {
                        data["Contact_Info"].map((contact) => {
                            return(
                                <a className="p-13" href={contact.Link} target='_blank'>
                                    <img src={process.env.REACT_APP_BACKEND_URL + contact.Icon.data.attributes.url} className='contact__icons' />
                                    {contact.Text}
                                </a>
                            )
                        })
                    }


                    {/* <a className="p-13" href='tel:+9251111444475'><span className='contact__icons'><IoMdCall /></span>UAN: +92 51 111 444 475</a>
                    <a className="p-13" href='https://wa.me/+923357454266/' target='_blank'><span className='contact__icons'><IoLogoWhatsapp /></span>+92 335 7454266</a>
                    <a className="p-13" href='mailto:info@siliconvillagepak.com'><span className='contact__icons'><MdEmail /></span>info@siliconvillagepak.com</a>
                    <a className="p-13" href='https://goo.gl/maps/U6Xc1gDnKgxqK7MGA' target='_blank'><span className='contact__icons'><HiLocationMarker /></span>Silver Square Plaza, Plot # 15, Street #73, Mehr Ali Road, F-11 Markaz, Islamabad, Pakistan</a> */}
                </div>
            </div>

            {/* BORDER */}
            <div className='footer__border'></div>

            <div className="container">
                <div className='footer__copyright'>
                    <p className="p-13">{data["Copyright_Text"]}</p>
                    {/* <div className='footer__copyright-menu hide-onmobile'>
                        <a className="p-13" href='/#aboutus'>About Us</a>
                        <a className="p-13" href='/#contact'>Contact</a>
                        <a className="p-13">Privacy Policy</a>
                        <a className="p-13">Sitemap</a>
                        <a className="p-13">Terms Of Use</a>
                    </div> */}
                </div>
            </div>
        </div>
    )
  )
}

export default Footer