import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { HiMenu } from 'react-icons/hi'
import { RxCross2 } from 'react-icons/rx'

import {FaFacebookSquare} from 'react-icons/fa'
import {RiInstagramFill} from 'react-icons/ri'
import {AiFillTwitterSquare} from 'react-icons/ai'

import './Nav.scss'
// import LOGO from '../../assets/navbar_logo.svg'
import LOGO from '../../assets/logo_with_text.png'
import { navLinks } from '../../utils/constants';

const Nav = () => {

  const [data, setData] = useState(null);
  const [navVisible, setNavVisible] = useState(false);

  useEffect(() => {
    axios.get(process.env.REACT_APP_BACKEND_URL + '/api/nav?populate=deep')
      .then(function (response) {
        // handle success
        setData(response.data.data.attributes.Navbar);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }, []);


  return (
    <>
      { data !== null && (
          <div id='nav'>
          <div className='nav__container'>

            <div className="container nav__flexcontainer">
              <a href="/" style={{display: 'flex', paddingTop: '5px', paddingBottom: '5px'}}>
                <img src={process.env.REACT_APP_BACKEND_URL + data["Logo"].data.attributes.url} alt="" />
              </a>
              {/* <ul className='hide-onmobile'>
                {
                  navLinks.map(item => (
                    <div>
                        <a href={item.path} name={item.key}>{item.display}</a>
                    </div>
                  ))
                }
              </ul> */}

              <div className="social__icons">
                {
                  data["Social_Media_Icons"].map((icon) => {
                    return (
                      <a href={icon.Link} className='hide-onmobile'>
                        <img src={process.env.REACT_APP_BACKEND_URL + icon["Icon"].data.attributes.url} alt="" style={{width: '40px', height: '40px'}} />
                      </a>
                    )
                  })
                }
                  {/* <a href="https://www.facebook.com/siliconvillagepakistan/" className='hide-onmobile'><FaFacebookSquare /></a>
                  <a href="https://www.instagram.com/siliconvillagepakistan/" className='hide-onmobile'><RiInstagramFill /></a>
                  <a href="https://twitter.com/siliconpak" className='hide-onmobile'><AiFillTwitterSquare /></a> */}
                  <a href={data["CTA_Button_Link"]}><button className='btn'>{data["CTA_Button_Text"]}</button></a>
              </div>

            {/* FOR SMALL DEVICES */}
            {/* {
              <ul className={'show-onmobile mob__nav ' + (navVisible? 'show__mobnav' : 'hide__mobnav')}>
                {
                  navLinks.map(item => (
                    <div>
                        <a href={item.path} name={item.key}>{item.display}</a>
                    </div>
                  ))
                }
                <a href='#contact'>Join Us</a>
              </ul>
            }

              <span className='menu__icon show-onmobile'>
                {
                  navVisible ? 
                    <RxCross2 onClick={() => setNavVisible(!navVisible)} />
                    :
                    <HiMenu onClick={() => setNavVisible(!navVisible)} />
                }
              </span> */}
            </div>
          </div>

          {/* NAVBAR PADDING ELEMENT (TO TAKE SPACE) */}
          <div className='nav__container' style={{position:'static', backgroundColor: 'transparent', color: '#fff'}}><p>navbar</p></div>
        </div>
        )
      }
    </>
  )
}

export default Nav