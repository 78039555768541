import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {AiOutlineZoomIn} from 'react-icons/ai'
import {AiOutlineZoomOut} from 'react-icons/ai'
import {IoMdDownload} from 'react-icons/io'
import {BsArrowRight} from 'react-icons/bs'
import {BsArrowLeft} from 'react-icons/bs'

import { Worker } from '@react-pdf-viewer/core';
import { Viewer, SpecialZoomLevel, ScrollMode } from '@react-pdf-viewer/core';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';



import './Brochure.scss'

const Brochure = () => {

const pageNavigationPluginInstance = pageNavigationPlugin();
const zoomPluginInstance = zoomPlugin();
const { CurrentScale, ZoomIn, ZoomOut } = zoomPluginInstance;
const { CurrentPageLabel } = pageNavigationPluginInstance;
const [data, setData] = useState(null);

useEffect(() => {
    axios.get(process.env.REACT_APP_BACKEND_URL + '/api/brochure?populate=deep')
      .then(function (response) {
        // handle success
        setData(response.data.data.attributes);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }, []);

useEffect(() => {
  document.addEventListener('keydown', detectKeyDown, true)
}, [])

const goToPreviousPage = () => {
  pageNavigationPluginInstance.jumpToPreviousPage();
}

const goToNextPage = () => {
  pageNavigationPluginInstance.jumpToNextPage();
}


const detectKeyDown = (e) => {

  // on LEFT ARROW key pressed
  if (e.keyCode === 37) {
    pageNavigationPluginInstance.jumpToPreviousPage();
  }

  // on RIGHT ARROW key pressed
  if (e.keyCode === 39) {
    pageNavigationPluginInstance.jumpToNextPage();
  }
}

  return (
    data !== null && (
      <div id='brochure' className='container'>

        {/* TOOLBAR */}
        <div className='custom__toolbar'>

          <p className='title hide-onmobile'>{data["Title"]}</p>

          <div className='middle__section'>

            
            <ZoomOut>
            {
                (props) => (
                    <span onClick={props.onClick} className='zoom__out zoom__icon'><AiOutlineZoomOut /></span>
                )
            }
            </ZoomOut>
            
            <div className='page__number'>
              {/* CURRENT PAGE */}
              <CurrentPageLabel>
                  {(props) => (
                      <span><span className='current__page'>{props.currentPage + 1}</span>/{props.numberOfPages}</span>
                  )}
              </CurrentPageLabel>
            </div>
            
            <ZoomIn>
            {
                (props) => (
                    <span onClick={props.onClick} className='zoom__in zoom__icon'><AiOutlineZoomIn /></span>
                )
            }
            </ZoomIn>
          </div>

          <a href={process.env.REACT_APP_BACKEND_URL + data["PDF"].data.attributes.url} download>
              <button className='p-18 btn download__btn'>
                  <IoMdDownload />
                  <span className='hide-onmobile'>{data["CTA_Button_Text"]}</span>
              </button>
          </a>
        </div>
      
        <div className='pdf__container'>

          {/* PDF */}
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div className='brochure-frame'>
              <Viewer
              defaultScale={SpecialZoomLevel.PageFit}
              scrollMode={ScrollMode.Page}
              fileUrl={process.env.REACT_APP_BACKEND_URL + data["PDF"].data.attributes.url}
              plugins={[pageNavigationPluginInstance, zoomPluginInstance]}/>
              </div>
          </Worker>


          <div className='next_prev__buttons'>
            <button className='p-18 btn previous__btn' onClick={goToPreviousPage}><BsArrowLeft /></button>
            <button className='p-18 btn next__btn' onClick={goToNextPage}>Next <span><BsArrowRight /></span></button>
          </div>

        </div>
      </div>
    )
  )
}

export default Brochure