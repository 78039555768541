import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {FiFileText} from 'react-icons/fi'
import {IoMdDownload} from 'react-icons/io'

import './BrochureSection.scss'

const BrochureSection = (props) => {

    const [data, setData] = useState(props.data);
    const [brochureDownloadLink, setBrochureDownloadLink] = useState(null);
    

    useEffect(() => {
        // if there is no download url
        if (!data["CTA_Button_Link"]) {
            axios.get(process.env.REACT_APP_BACKEND_URL + '/api/brochure?populate=deep')
            .then(function (response) {
              // handle success
              setBrochureDownloadLink(process.env.REACT_APP_BACKEND_URL + response.data.data.attributes["PDF"].data.attributes.url);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            })
            .finally(function () {
              // always executed
            });
        } else {
            setBrochureDownloadLink(data["CTA_Button_Link"])
        }
      }, []);

  return (
    <div id="brochuredownload">
        <div className='container brochure__section'>
            <p className="heading-28">{data["Title"]}</p>
            <p className="p-18 color-gray-80 desc">{data["Description"]}</p>

            <div className="buttons__section">
                {
                    data["CTA_Buttons"].map((button) => {

                        if (button["Button_Type"]=== 'Primary') {
                            return (<a href={button["CTA_Button_Link"]}>
                                <button className='p-18 btn view__btn'>
                                    <FiFileText />
                                    {button["CTA_Button_Text"]}
                                </button>
                            </a>)
                        }

                        if (button["Button_Type"]=== 'Secondary') {
                            return(<a href={button["CTA_Button_Link"]}>
                                <button className='p-18 btn download__btn'>
                                    <IoMdDownload />
                                    {button["CTA_Button_Text"]}
                                </button>
                            </a>)
                        }

                        // Button to download pdf broucher
                        if (button["Button_Type"]=== 'DownloadPDF' && brochureDownloadLink) {
                            return(<a href={brochureDownloadLink} download>
                                <button className='p-18 btn download__btn'>
                                    <IoMdDownload />
                                    {button["CTA_Button_Text"]}
                                </button>
                            </a>)
                        }
                    })
                }
            </div>

        </div>
    </div>
  )
}

export default BrochureSection