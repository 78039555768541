import React, {useEffect, useState} from 'react'

import './AboutUs.scss'
import ABOUTUS_IMG from '../../assets/aboutus.svg'
import { useInView } from 'react-intersection-observer';
import { useInViewOptions } from '../../utils/constants';
import { removeSelectedNavItem, selectActiveItemOnNav } from '../../utils/functions';

const AboutUs = (props) => {

  const [data, setData] = useState(props.data);

  const descriptionTextColor = data["Description_TextColor"].data ? data["Description_TextColor"].data.attributes.Color_Code : '';

  const { ref, inView, entry } = useInView(useInViewOptions);

  // useEffect(() => { 
  //   if (inView)  {
  //       selectActiveItemOnNav('aboutus');
  //   } else removeSelectedNavItem('aboutus');
  // }, [inView]);
  
  return (
    <div id="aboutus" ref={ref} style={{backgroundColor: data["Background_Color"]}}>
        <div className="container aboutus__container" style={data.ExtraBottomPadding ? {paddingBottom: '15rem'} : {}}>

          <div className="aboutus__txtcontainer" data-aos="slide-up">
            <p className='heading-28'>{data["Title"]}</p>
            <p className='p-18' style={{color: descriptionTextColor}} dangerouslySetInnerHTML={{__html:data["Description"]}}></p>
          </div>

          <img src={process.env.REACT_APP_BACKEND_URL + data["Image"].data.attributes.url} alt="About Us Image" data-aos="zoom-in"/>

        </div>
    </div>
  )
}

export default AboutUs