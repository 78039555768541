import React, {useEffect, useState} from 'react'
import axios from 'axios'

import University from '../university/University';
import Header from '../header/Header';
import AboutUs from '../aboutus/AboutUs';
import Vision from '../vision/Vision';
import Stza from '../stza/Stza';
import CapitalSmartCity from '../capitalSmartCity/CapitalSmartCity';
import Facilities from '../facilities/Facilities';
import Molecule from '../molecule/Molecule';
import ComingSoon from '../comingsoon/ComingSoon';
import Contact from '../contact/Contact';

import AOS from 'aos';
import 'aos/dist/aos.css';
import BrochureSection from '../brochureSection/BrochureSection';
import BusinessPark from '../businesspark/BusinessPark';
import ImgMiddle from '../imgMiddle/ImgMiddle';
import Footer from '../footer/Footer';
import Nav from '../nav/Nav';

const Home = () => {

  const [components, setComponents] = useState([]);

  useEffect(() => {
    axios.get(process.env.REACT_APP_BACKEND_URL + '/api/home?populate=deep')
      .then(function (response) {
        // handle success
        setComponents(response.data.data.attributes.HomeComponents);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
      });
  }, []);

  AOS.init();

  AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
    
  
    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 100, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    once: true, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
  
  });

  return (
    <>

    {
      components.map(component => (
        <>
          {/* {
            component.__component === "navbar.navbar" && (
              <Nav data={component}/>
            )
          } */}
          {
            component.__component === "header.header" && (
              <Header data={component}/>
            )
          }
          
          {/* TODO ABOUT SECTION SHOULD HAVE DYNAMIC BACKGROUND */}
          {/* TODO Add heading component "Facilities to make it possible?" */}
          {
            component.__component === "general.image-right-text-left" && (
              <AboutUs data={component}/>
            )
          }
          {
            component.__component === "sv-components.vision-component" && (
              <div data-aos="zoom-in"><Vision data={component} /></div>
            )
          }
          {
            component.__component === "general.image-left-text-right" && (
              <div data-aos="zoom-in"><BusinessPark data={component} /></div>
            )
          }
          {
            component.__component === "general.image-middle-text-rl" && (
              <div data-aos="zoom-in"><ImgMiddle data={component} /></div>
            )
          }
          {
            component.__component === "sv-components.molecule" && (
              <div data-aos="zoom-in"><Molecule data={component} /></div>
            )
          }
          {
            component.__component === "general.title-description-cta" && (
              <div data-aos="zoom-in"><BrochureSection data={component} /></div>
            )
          }
          {
            component.__component === "sv-components.coming-soon" && (
              <div data-aos="zoom-in"><ComingSoon data={component} /></div>
            )
          }
          {
            component.__component === "sv-components.stza" && (
              <div data-aos="zoom-in"><Stza data={component} /></div>
            )
          }
          {
            component.__component === "general.contact-form" && (
              <div data-aos="zoom-in"><Contact data={component} /></div>
            )
          }
          {/* {
            component.__component === "footer.footer" && (
              <div data-aos="zoom-in"><Footer data={component} /></div>
            )
          } */}
        </>
      ))
    }
      {<div className='background__secondary'>
        {/* <Header/> */}
        
        {/* <AboutUs/> */}
      </div>}
      
      {/* <div data-aos="zoom-in"><CapitalSmartCity /></div>
      <div data-aos="slide-up"><University /></div>
      <div data-aos="slide-up"><Facilities /></div>
      <div data-aos="zoom-in"><Molecule /></div>
      <div data-aos="fade-out"><BrochureSection /></div>
      <div data-aos="fade-out"><ComingSoon /></div>
      <div data-aos="fade-out"><Stza /></div> */}
      {/* <div data-aos="fade-out"><Contact /></div> */}
    </>
  )
}

export default Home