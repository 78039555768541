import { navLinks } from "./constants";

export const removeSelectedNavItem = (key) => {
    document.getElementsByName(key).forEach( element => {
        element.classList.remove('active-nav-link');
    })
}

export const selectActiveItemOnNav = (key) => {

    navLinks.forEach( (navLink) => {
        document.getElementsByName(navLink.key).forEach( element => {
            element.classList.remove('active-nav-link')
        })
    })

    document.getElementsByName(key).forEach( element => {
        element.classList.add('active-nav-link');
    })
}