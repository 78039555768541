import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Nav from './components/nav/Nav';
import Footer from './components/footer/Footer';
import Home from './components/home/Home';
import Brochure from './components/brochure/Brochure';

function App() {
  return (
    <div className="App">
      <Nav />
      <BrowserRouter>
      <Routes>
          <Route path="/brochure" element={<Brochure />} />
          <Route path="/" element={<Home />}>
        </Route>
      </Routes>
    </BrowserRouter>
      
      <Footer />
  </div>

  );
}

export default App;
