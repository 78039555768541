import React, {useState, useRef} from 'react'
import {AiOutlineLoading3Quarters} from 'react-icons/ai'
import emailjs from '@emailjs/browser';


import './Contact.scss'

const Contact = (props) => {

  const [data, setData] = useState(props.data);

  const [loading, setLoading] = useState(false);

  // to show success message if set to true otherwise show form
  const [formSubmitState, setFormSubmitState] = useState(false);


  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [formSubmissionError, setFormSubmissionError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const form = useRef();

  function isEmailValid(email) {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEmail.test(email);
  }

  function clearNameError() {
    setNameError('');
  }

  function clearEmailError() {
    setEmailError('');
  }

  function clearMessageError() {
    setMessageError('');
  }

  const clearForm = () => {
    setLoading(false);
    document.getElementById("name").value = "";
    document.getElementById("email").value = "";
    document.getElementById("message").value = "";
  }

  const resetForm = () => {
    setFormSubmitState(false);
    clearForm();
  }


  const submitForm = (e) => {
    e.preventDefault();

    var hasError = false;

    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;
    setFormSubmissionError('');
    setSuccessMsg('');
    
    if (name.toString() === '') {
      setNameError('Please enter your name');
      hasError = true;
    } else if (name.toString().length <= 2) {
      setNameError('Name should contain atleast 3 characters');
      hasError = true;
    }else {
      clearNameError();
    }

    if (!isEmailValid(email)) {
      setEmailError('Please enter correct email');
      hasError = true;
    } else {
      clearEmailError();
    }

    if (message.toString() === '') {
      setMessageError('Please enter your message');
      hasError = true;
    } else {
      clearMessageError();
    }

    // if there is some error in form then don't submit it
    if (hasError) {
      return;
    }


    emailjs.sendForm('service_83kct61', 'template_csj363h', form.current, 'M8kjlm_mAWQJKwsJa')
      .then((result) => {
          clearForm();
          setFormSubmitState(true);
          setSuccessMsg('Subscribed Successfully!');
      }, (error) => {
          console.log(error.text);
          setLoading(false);
          setFormSubmissionError('Something went wrong, try again');
      });


    // // AWS SES SERVICE
    // const requestOptions = {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ name: name, email: email, message: message }),
    //   mode: 'no-cors' // 'cors' by default
    // };

    // fetch('https://uk0tdfm9d4.execute-api.eu-west-2.amazonaws.com/default/SendEmailLambdaFunction', requestOptions)
    // .then(response =>  {

    //   // Because this API call was giving CORS-ORIGIN error so we are using "mode: no-cors" in header of this request
    //   // no-cors request only return 'opaque' response which means that it doesnt return any body data
    //   if (response.type === 'opaque') {
    //     clearForm();
    //     setFormSubmitState(true);
    //     setSuccessMsg('Subscribed Successfully!');
    //   } else {
    //     setLoading(false);
    //     setFormSubmissionError('Something went wrong, try again');
    //   }
    // }).catch(response => { //handle error
    //     setLoading(false);
    //     setFormSubmissionError('Something went wrong, try again');
    // }) 

    setLoading(true);
  }

  return (
    <form ref={form} onSubmit={submitForm}>
    <div id='contact'>
        <div className="container contact__container">
            <div className='joinus'>
                <p className="heading-28 mb-2">{data["Title"]}</p>
                <p className="p-18 color-gray-80">{data["Description"]}</p>
            </div>
            <div className="contactform__container">
                <p className="heading-24 w-100 text-align-center font-weight-600">{data["Form_Title"]}</p>

                {/* NAME */}
                <p className='p-18 color-gray-80'>{data["Title_1"]}</p>
                <input type="text" name='name' id='name' placeholder={data["Placeholder_1"]} />
                <p className='error-msg mb-1'>{nameError}</p>

                {/* EMAIL */}
                <p className='p-18 color-gray-80'>{data["Title_2"]}</p>
                <input type="email" name='email' id='email' placeholder={data["Placeholder_2"]} />
                <p className='error-msg mb-1'>{emailError}</p>


                {/* MESSAGE */}
                <p className='p-18 color-gray-80 w-100'>{data["Title_3"]}</p>
                <textarea type="text" name='message' id='message' placeholder={data["Placeholder_3"]} />
                <p className='error-msg mb-2'>{messageError}</p>

                {/* BUTTON OR LOADING */}
                { loading ? <div class="loader mb-1"><AiOutlineLoading3Quarters /></div> 
                  :
                  <div>
                    {/* onClick={submitForm} */}
                    <button className='btn w-100' type="submit" value="Send" >{data["CTA_Button_Text"]}</button>
                    <p className='success-msg mt-1'>{successMsg}</p>
                    <p className='error-msg'>{formSubmissionError}</p>
                  </div>
                }
            </div>
        </div>
    </div>
    </form>
  )
}

export default Contact