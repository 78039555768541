import React, {useEffect, useState} from 'react'

import './Vision.scss'
import INFRASTRUCTURE_IMG from '../../assets/vision1.svg'
import EDUCATION_IMG from '../../assets/vision2.svg'
import BUSINESSES_IMG from '../../assets/vision3.svg'
import ENVIRONMENT_IMG from '../../assets/vision4.svg'
import VISION_IMG from '../../assets/vision_img.svg'
import VISION_DETAILS from '../../assets/vision_details.svg'
import VISION_DETAILS_MOB from '../../assets/vision_details_mob.svg'
import { useInView } from 'react-intersection-observer';
import { useInViewOptions } from '../../utils/constants';
import { removeSelectedNavItem, selectActiveItemOnNav } from '../../utils/functions';

const Vision = (props) => {

  const [data, setData] = useState(props.data);

  const { ref, inView, entry } = useInView(useInViewOptions);

  useEffect(() => { 
    if (inView)  {
        selectActiveItemOnNav('siliconvillage');
    } else removeSelectedNavItem('siliconvillage');
  }, [inView]);


  return (
    <div ref={ref}>
      <div className='multiplevision__container container' id='siliconvillage'>    
        {
          data["VisionBlock"].map(vision => (
            <div className='vision'>
              <div className='vision__container'>
                <img src={process.env.REACT_APP_BACKEND_URL + vision["Image"].data.attributes.url} alt={vision["Title"]} />
                <div className="vision__details">
                    <p className="p-17">{vision["Description"]}</p>
                </div>
              </div>
              <div className='vision__title'>
                <p className="p-20">{vision["Title"]}</p>
              </div>
            </div>
          ))
        }
      </div>

      {
        data["TwoBlockImages"] && (
          <div id='vision'>
            <img src={process.env.REACT_APP_BACKEND_URL + data["TwoBlockImages"].Image_Left.data.attributes.url} className='vision__img' alt="Vision Image" />
            {/* <img src={VISION_DETAILS} className='visiondetails__img' alt="Vision Details" /> */}
            <img src={process.env.REACT_APP_BACKEND_URL + data["TwoBlockImages"].Image_Right.data.attributes.url} className='visiondetails__img' alt="Vision Details" />
            {/* <img src={VISION_DETAILS_MOB} className='show-onmobile visiondetails__img' alt="Vision Details" /> */}
          </div>
        )
      }

    </div>
  )
}

export default Vision